import { CSSProperties, useContext, useState } from 'react';
import './LevelSelector.scss'
//import project from '../../data/project'
import { ProjectContext } from '../../context/projectContext';
import { Badge } from 'react-bootstrap';

type Props = {
    onChangeLevel: (level:number) => void,
    style?: CSSProperties | undefined
}

const LevelSelector = ({onChangeLevel, style}: Props) => {

    const context = useContext(ProjectContext);
    const project = context?.project;
    const levels = project?.buildings[0]?.levels.sort((a,b) => a.level - b.level).reverse();

    const selectLevel = (level: number) => {
        context?.setSimulator({...context.simulator, current_level: level});
        onChangeLevel(level);
    }

    return (
        <div className="level-selector" style={style}>
            <ul>
                { levels?.map(({level,name,shortName, availability}: any) => {
                    return <li 
                            className={`${(context?.simulator.current_level == level ? 'active' : '')}`}
                            onClick={() => selectLevel(level)}
                            title={name}
                            key={level} >
                                {shortName} {availability>0 && <Badge bg="white">{availability}</Badge>}
                        </li>
                    })
                }
            </ul>
        </div>
    )
}

export default LevelSelector;
