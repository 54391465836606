import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import './Footer.scss';

const Footer = () => {
    return (
        <footer className="footer">
            <span className="footer--background"></span>
            <Container fluid>
                <Row className="align-items-center justify-content-center">
                    <Col xs='12' lg='2' className="py-1">
                        <span className="copyright">&copy; All Rights Reserved <span className="year">{new Date().getFullYear()}</span></span>
                    </Col>
                    <Col xs='12' lg='6'>
                        {/*<p>
                            Av. Real 301, Delegación Centro, Querétaro, Qro., C.P. 76125
                        </p>*/}
                        <Row className="footer--legals align-items-center">
                            <Col xs='12' lg='4' >
                                <sup>*</sup>Precios sujetos a disponibilidad.
                            </Col>
                            <Col xs='6' md='4'>
                                <Button variant='text' href="#/aviso-de-privacidad">Aviso de Privacidad</Button>
                            </Col>
                            <Col xs='6' md='4'>
                                <Button variant='text' href="#/aviso-de-no-discriminacion">Aviso de no discriminación</Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs='12' lg='2'>
                        <Button href="https://renderinc.mx/" target="_blank" variant="text"><span className="lg-renderinc"></span></Button>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;