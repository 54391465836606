import React, { useContext, useEffect, useState } from 'react';
import { API_URL, ProjectContext } from '../../context/projectContext';
import { Link } from 'react-router-dom';
import Layout from '../Layout/Layout';
import './Home.scss';
import Button from 'react-bootstrap/Button';
import MasterPlan from '../MasterPlan/MasterPlan';
import { useSessionContext } from '../../context/sessionContext';

//import project from '../../data/project'

const Home = () => {
    const context = useContext(ProjectContext);
    const project = context?.project;
    const [ showIntro, setShowIntro ] = useState(true);
    //const [ showIntro, setShowIntro ] = useState(true);
    const [ isClosing, setIsClosing ] = useState(false);
    const [ isActive, setIsActive ] = useState(true);
    const [ autoplay, setAutoplay ] = useState(false);
    const [ session ] = useSessionContext()

    const handleCloseIntro = () => {
        if(isClosing) return;
        setIsClosing(true);
        setIsActive(false);
        setAutoplay(true);
        setTimeout(() => {
            setIsClosing(false);
            setShowIntro(false);
        },1000)
    }

    useEffect(() => {
        setAutoplay(true);
    },[])

    return (
        <Layout className={`${isClosing ? 'home--closing' : ''} ${isActive ? 'home--active' : ''}`}>
            <>
                { showIntro &&
                    <div className='home--wp' style={{zIndex: 10}}>
                        <div className={ `home--content-wp`}>
                            <span className="home--background" style={{ backgroundColor: `${ project?.styles.intro.backgroundColor }` }}></span>
                            <div className="home--content">
                                <span className="home--logo" style={{ backgroundImage: `url(${ project?.styles.intro.logo })` }}></span>
                                <div className="home--welcome">
                                    <p>
                                        YCONIA es el lugar para disfrutar la vida al máximo y estar cerca de lo que más te gusta.
                                    </p>
                                </div>
                                <div className="home--cta">
                                    <Button variant="primary" onClick={ handleCloseIntro } className="text-white">Aparta tu Depa</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="session-buttons">
                    {/*<Button href="/" variant='text' className={`logout-btn ${window.location.hash === '#/home' ? 'forced-index' : ''}`}>Salir</Button>
                    <Button variant="text" href={API_URL} target='_blank' className="btn-panel ms-4">Panel</Button>*/}
                </div>
                <MasterPlan autoplay={autoplay} handleAutoplayCallback={() => setAutoplay(false)} />
            </>
        </Layout>
    )
}

export default Home;