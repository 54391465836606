import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import VideoTransition from '../VideoTransition/VideoTransition';
import './BuildingSpinner.scss'
import { ProjectContext } from '../../context/projectContext';
import { useNavigate } from 'react-router-dom';

type Props = {
    autoplay: boolean,
    handleAutoplayCallback: () => void
}

const BuildingSpinner = ({autoplay, handleAutoplayCallback} : Props) => {

    const context = useContext(ProjectContext);
    const navigate = useNavigate();

    const ref = useRef<HTMLInputElement>(null);
    const data = context?.project.assets.spinner;
    const [currentIdx, setCurrentIdx] = useState(data.sides.length - 3 || 0);
    //const [frame,setFrame] = useState('');
    const [transition,setTransition] = useState('');
    const [imageLoader,setImageLoader] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlayable, setIsPlayable] = useState(false);

    const left = () => (currentIdx-1 < 0) ?  data.sides.length - 1 : currentIdx-1
    const right = () => (currentIdx+1 > data.sides.length - 1) ? 0 : currentIdx+1
    const goLeft = () => setCurrentIdx(left());
    const goRight = () => setCurrentIdx(right());
    const goTop = () => setCurrentIdx(-1);
    const reset = () => {
        setCurrentIdx(data.sides.length - 3 || 0);
        spinBuilding('right');
    }


    const startTransition = () => {
        setIsPlaying(true);
        ref.current?.classList.add('is-spinning');
        ref.current?.classList.remove('is-imageReady');
    }

    useEffect(() => {
        ref.current?.classList.remove('is-spinning');
    },[currentIdx])

    useEffect(() => {
        if(isPlayable){
            startTransition();
        }
    },[isPlayable])

    const spinBuilding = (dir: string) => {
        if(!dir) return;

        // prepare frame and preload
        ref.current?.classList.remove('dir-left','dir-right','dir-top');
        ref.current?.classList.add(`dir-${dir}`);
        ref.current?.classList.add('is-loading');
        ref.current?.classList.add('is-preparing');
        ref.current?.classList.remove('is-imageReady');
        ref.current?.classList.remove('is-longWait');
        setIsPlayable(false);
        const waitTimer = setTimeout(() => {
            ref.current?.classList.add('is-longWait');
        },5000)
        const preloadedImage = new Image();
        const imageUrl = data.sides[currentIdx].endFrame;
        preloadedImage.src = imageUrl;
        preloadedImage.onload = () => {
            setImageLoader(imageUrl);
            setTimeout(() => {
                ref.current?.classList.add('is-imageReady');
                ref.current?.classList.remove('is-preparing');
                setTimeout(() => {
                    // prepare dir-transition and play
                    if(dir==='top'){
                        setTransition(data.sides[currentIdx].transitions['overhead']);
                        goTop();
                    }
                    else{
                        setTransition(data.sides[currentIdx].transitions[ dir === 'left' ? 'left' : 'right']);
                        dir === 'left' ? goLeft() : goRight();
                    }
                    // change currentIndex
                    ref.current?.classList.remove('is-overhead');
                    clearTimeout(waitTimer);
                },1000)
            },300)
        }
    }

    useEffect(() => {
        if(autoplay){
            reset();
            spinBuilding('right');
        }
    },[autoplay])

    useEffect(() => {
        const imageUrls = data.sides.map((s:any) => s.endFrame);
        const preLoadImages = () => {
            imageUrls.forEach((imageUrl : string) => {
              const image = new Image();
              image.src = imageUrl;
            });
        }; 
        preLoadImages();
    },[])

    return (
        <>
            <div className='building-spinner' ref={ref}>
                <span className='bar-loader'></span>
                <Button className='building-spinner--overhead-button' variant='primary' onClick={e => spinBuilding('top')}>Consultar disponibilidad</Button>
                <div className={`building-spinner--transition ${isPlayable ? '' : 'is-loading'}`}>
                    <span className='building-spinner--transition-loader' style={{ backgroundImage: `url("${imageLoader}")` }}></span>
                    { transition &&
                        <VideoTransition
                            url={transition} 
                            isPlaying={isPlaying} 
                            handleOnReady={() => {
                                setIsPlayable(true);
                            }}
                            handleCallback={() => {
                                setIsPlaying(false);
                                ref.current?.classList.remove('is-spinning',);
                                ref.current?.classList.remove('is-loading');
                                handleAutoplayCallback();
                                if(currentIdx=== -1)
                                    setTimeout(() => {
                                        navigate('/niveles');
                                    },1000)
                            } }                    
                        />                        
                    }
                </div>
                
                <div className="building-spinner--controls">
                    <Button variant="primary" onClick={e => spinBuilding('left')}><span className="icon icon--left"></span> <span className="loader"></span></Button>
                    <Button variant="primary" onClick={e => spinBuilding('right')}><span className="icon icon--right"></span> <span className="loader"></span></Button>
                </div>
            </div>
        </>
    )
}

export default BuildingSpinner