import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap"
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { IArea } from "../../@types/project";
import { API_URL, ProjectContext } from "../../context/projectContext";
import './InterestedCard.scss';
import axios from "axios";


type Props = {
    area?: IArea,
    show: boolean,
    handleClose: any,
}

interface IFormInputs {
    area?: string,
    name: string,
    email: string,
    phone: string,
  };

const InterestedCard = ({area, show, handleClose} : Props) => {

    const [showModal, setShowModal] = useState(show);
    const context = useContext(ProjectContext);
    const level = context?.project.buildings[0].levels.find((l : any) => l.level === context?.simulator.current_level)
    const onHandleClose = () => { 
        //setShowModal(false);
        handleClose(false);
    };
    useEffect(() => {
        setShowModal(show);
    },[show])

    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

    const { register, handleSubmit, control, reset, formState: { errors } } = useForm<IFormInputs>({
        defaultValues: {
          area: area?.area,
        }
      });
    
      const url = `${API_URL}/api/v1`;
      const fetchData = async (data : IFormInputs) => {
        setError(null);
        setLoading(true);
        setIsSubmitSuccessful(false);
        try {
          const response = await axios.post(`${url}/email/send/reserve`,data);
          setIsSubmitSuccessful(true);
        } catch (err: unknown) {
          /*if (axios.isAxiosError(err)) {
            // Error específico de axios
            setError(err.message);
          } else if (err instanceof Error) {
            // Error genérico de JavaScript
            setError(err.message);
          } else {
            // Error desconocido*/
          setError('Hubo un error al enviar, por favor intenta nuevamente o utiliza alguno de nuestros medios alternativos de contacto.');
        } finally {
          setLoading(false);
        }
      };
      const onSubmit: SubmitHandler<IFormInputs> = data => {
        fetchData(data);
      }
    
      useEffect(() => {
        if(!isSubmitSuccessful) return;
        reset({
          name: '',
          email: '',
          phone: '',
        })
        setError('¡Gracias! Uno de nuestros representantes se pondrá en contacto a la brevedad.')
        setTimeout(() => {
          handleClose();
        },2000)
      }, [isSubmitSuccessful])

    return (
        <Modal
            className="interested-card"
            show={showModal}
            fullscreen={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={onHandleClose}
            style={{zIndex: 3000}}
        >
          <Modal.Body>
            <Container fluid style={{ minHeight: '100vh' }}>
                <Row className="d-flex justify-content-between align-items-stretch" style={{ minHeight: '100vh' }}>
                    <Col md='12' className="p-3 bg-dark h-full d-flex align-items-center justify-content-center">
                        <Button className="btn-close" variant="secondary" onClick={handleClose}></Button>
                        <div className="interested-card--content text-center text-primary">
                            <span className="home--logo" style={{ backgroundImage: `url(${ context?.project.styles.intro.logo })` }}></span>
                            <p className="mt-4 text-white">Estoy interesado en</p>
                            <h3 className="my-4 text-primary">
                                {area?.prototype.name} {area?.area}
                            </h3>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group my-2">
                                    <input type="text" className="form-control" placeholder="Nombre" {...register("name", { required: true, pattern: /^[A-Za-z]+$/i })}/>
                                    {errors.name && <span className='form-error'>Campo no válido</span>}
                                </div>
                                <div className="form-group my-2">
                                    <input type="text" className="form-control" placeholder="Teléfono" {...register("phone", { required: true, pattern: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g })} />
                                    {errors.phone && <span className='form-error'>Campo no válido</span>}
                                </div>
                                <div className="form-group my-2">
                                    <input type="text" className="form-control" placeholder="Email" {...register("email", { required: true, pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i })} />
                                    {errors.email && <span className='form-error'>Campo no válido</span>}
                                </div>
                                <div className="actions">
                                    <div>
                                        <Form.Control type="hidden" {...register("area")} />
                                        <Button type="submit" variant="primary" className={`text-white ${loading ? 'is-loading' : ''}`}><span className="ico-loader"></span> Enviar</Button>
                                        {error && <div className='form-error response'>{error}</div>}
                                    </div>
                                    <Button variant="text" className="text-white mt-2 btn-sm" onClick={handleClose}>Cancelar</Button>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
          </Modal.Body>
        </Modal>
    )
}

export default InterestedCard;