import { Col, Container, Row } from 'react-bootstrap';
import Layout from '../Layout/Layout';

const Privacy = () => {
    return (
        <Layout>
            <>
                <Container className='layout-article'>
                    <Row>
                        <Col>
                            <h1>AVISO DE PRIVACIDAD</h1>
                            <p className='text-end'>
                                01 de enero, del 2024.
                            </p>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec venenatis ipsum at purus vulputate tincidunt. Donec viverra pretium leo sit amet varius. Suspendisse aliquet sem urna, sed lacinia arcu consectetur sed. Phasellus laoreet varius mi a interdum. Nulla sed felis quam. Praesent molestie ullamcorper quam a condimentum. Vestibulum ultricies, nisl ac luctus maximus, ex turpis ornare lectus, sit amet euismod quam metus sed turpis. Morbi non bibendum magna. Vestibulum auctor lorem dignissim magna varius congue. Aliquam commodo lacinia nisl, vitae tempor sapien semper ac. Nulla facilisi. Sed in faucibus massa, at pulvinar elit. Sed tempus ligula eu ullamcorper finibus. Maecenas ac dolor lacus. In molestie, lorem non placerat porta, dui metus auctor sapien, at interdum augue nisl a purus.</p>

<p>Fusce elementum augue pharetra sollicitudin aliquam. Nam accumsan massa nec dui aliquet ultrices. Fusce et neque imperdiet, commodo massa quis, scelerisque nisl. Suspendisse enim dolor, sollicitudin non sodales ut, luctus et dui. Donec molestie libero sed felis maximus, ac scelerisque nibh rhoncus. Duis ultrices ullamcorper nibh, in dignissim risus ultricies nec. Praesent id auctor ante.</p>

<p>Proin tincidunt turpis non arcu tempor, a volutpat odio eleifend. Ut faucibus pulvinar erat et blandit. Fusce ultrices massa in volutpat tincidunt. In convallis risus tortor, vel pretium erat pretium et. Integer scelerisque condimentum nunc, quis fermentum tortor semper a. Vivamus tempus a est a facilisis. Praesent ac velit nibh. Vestibulum et fermentum purus, vel pretium quam. Pellentesque quis vulputate velit. Aliquam erat volutpat. Proin ac urna a ante vehicula ullamcorper nec eu velit. Vestibulum in arcu bibendum, placerat dui at, interdum mi. Nunc ac faucibus dui. In hac habitasse platea dictumst. Cras sollicitudin ligula sed finibus rhoncus. Praesent quam tortor, convallis non dui et, bibendum dapibus lacus.</p>

<p>Etiam vitae mi lobortis, dictum nulla eget, finibus risus. Nullam tristique leo id elit porttitor dapibus. Pellentesque lacinia sed mauris non feugiat. Morbi id urna arcu. Praesent feugiat ligula nec erat ultricies lobortis. Donec id vehicula diam. Vivamus malesuada auctor nunc et rutrum. Phasellus eu ornare odio, in pulvinar magna. In hendrerit faucibus ex a iaculis. Etiam at vestibulum enim, scelerisque lobortis sapien. Curabitur quis rutrum tortor. Fusce non laoreet erat. In rutrum luctus nibh, vitae cursus justo. Sed consectetur eu sapien quis facilisis. Mauris iaculis nisl sed nisi porttitor aliquam. Vestibulum non eleifend ipsum.</p>

<p>Nam non accumsan nulla. Nam ut tortor a urna dapibus pharetra. Nam libero turpis, blandit ac lacinia eu, interdum quis ante. Etiam eu tellus sit amet dolor lobortis porta. Sed et lacus sit amet augue vehicula laoreet. Quisque id lobortis risus. Suspendisse ac urna maximus, ultricies sapien vel, congue urna. Nulla fermentum metus sed lacus sollicitudin feugiat. Vestibulum sollicitudin dolor et libero placerat faucibus. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                        </Col>
                    </Row>
                </Container>
            </>
        </Layout>
    )
}

export default Privacy;