import { Button, Table } from 'react-bootstrap';
import './BuildingFloorTable.scss';
import { BuildingLevel, IArea, IBuilding, IStatusConfig } from '../../@types/project';

export interface BuildingFloorTableProps {
  building: IBuilding,
  level: BuildingLevel | undefined,
  statusConfig: IStatusConfig[],
  handleSelectedArea: (area: IArea) => void,
}

export function BuildingFloorTable({building,level,statusConfig, handleSelectedArea} : BuildingFloorTableProps) {

  const getStatusConfig = (status : any) => statusConfig.find(s => s.id === parseInt(status))

  return (
    <div className='building-floor-table'>
      <Table hover>
        <thead>
          <tr>
            <th className='text-center'><br /> Residencia</th>
            <th className='text-center'><br /> Nivel</th>
            <th className='text-center'><span className='icon icon--room'></span><br /> Recamaras</th>
            <th className='text-center'><span className='icon icon--bath'></span><br /> Baños</th>
            <th className='text-center'><span className='icon icon--terrace'></span><br /> Terraza</th>
            <th className='text-center'><span className='icon icon--size'></span><br /> Superficie</th>
            <th className='text-center'><br /> Estatus</th>
            <th className='text-center'></th>
          </tr>
        </thead>
        <tbody className='text-center'>
          { level && level.areas.map((a : IArea) => (
            <tr onClick={() => { if(a?.status==1) handleSelectedArea(a); }}>
            <td className='text-primary'><small className='text-muted text-xs'>{a.prototype_code?.substring(1)}</small> - {a.area}</td>
            <td>{level.name}</td>
            <td>{a?.prototype.rooms || '-'}</td>
            <td>{a?.prototype.bathrooms || '-'}</td>
            <td>{parseFloat(a?.terrace).toFixed(2).replace('.00','') || '-'} m<sup>2</sup></td>
            <td>{parseFloat(a?.surface).toFixed(2).replace('.00','') || '-'} m<sup>2</sup></td>
            <td style={{ color: getStatusConfig(a?.status)?.textColor }}>{ getStatusConfig(a?.status)?.label }</td>
            <td><Button className='btn-sm text-white' onClick={() => handleSelectedArea(a)} disabled={a.status < 1 || a.status >= 2 ? true : false}>Detalles</Button></td>
          </tr>
          ))
          }
        </tbody>
      </Table>
    </div>
  );
}

export default BuildingFloorTable;
