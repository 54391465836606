import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import './VideoTransition.scss'

type Props = {
    url: string,
    isPlaying: boolean,
    handleOnReady: (params: any) => any,
    handleCallback: (params: any) => any,
}

const VideoTransition = ({url,isPlaying,handleOnReady,handleCallback} : Props) => {

    const [isChangingUrl, setIsChangingUrl] = useState(false);

    useEffect(() => {
        setIsChangingUrl(true)
        setTimeout(() => {
            setIsChangingUrl(false);
        },350)
    },[url])


    return (
        <div className={ `view--transition`}>
            { !isChangingUrl &&
                <ReactPlayer url={ url } playsinline={true} playing={isPlaying} controls={false} volume={0} muted={true} width='100%' height='100%' onReady={() => {handleOnReady(true)}} onEnded={() => handleCallback(true)} config={{ file: { attributes: { playsInline: true, }, }, }} />
            }
        </div>
    )
}
export default VideoTransition;