import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import ExternalContentModal from '../ExternalContentModal/ExternalContentModal';

type Props = {
    className?: string,
    style?: React.CSSProperties,
    children: JSX.Element,
    leftbar?: JSX.Element,
    rightbar?: JSX.Element
}
const Layout =({className, style, children, leftbar, rightbar}: Props) => {
    const [extModalShow, setExtModalShow] = useState<boolean>(false);
    const [modalVariant, setModalVariant] = useState<string>('');
    const openModal = (variant: string) => {
        setModalVariant(variant);
        setExtModalShow(true)
    }
    return(
        <Container fluid className={`layout-container px-0 ${className}`}>
            <div className='turn-device-modal'>
                <div>
                    <p>
                        Por favor, gira tu dispositivo para visualizar correctamente este sitio.
                    </p>
                </div>
            </div>
            <Header />
            <main>
                <Row className='px-0 mx-0'>
                    {
                        leftbar && 
                        <Col className='layout-aside'>
                            { leftbar }
                        </Col>
                    }
                    <Col className='layout-main px-0' style={style}>
                        {children}
                    </Col>
                    {
                        rightbar && 
                        <Col className='layout-aside'>
                            { rightbar }
                        </Col>
                    }
                </Row>
            </main>
            <Footer />
            <>
                <div className='ext-modals-buttons'>
                    {/*<Button variant="primary" className='btn-ext-modal text-white' href="https://yconia.mx/360/" target='_blank'>
                        Tour 360°
                    </Button>*/}
                    <Button variant="primary" className='btn-ext-modal text-white' onClick={() => openModal('video')}>
                        Ver video
                    </Button>
                </div>

                <ExternalContentModal
                    variant={modalVariant}
                    show={extModalShow}
                    onHide={() => setExtModalShow(false)}
                    className='ext-modal'
                />
            </>
        </Container>
    )
}

export default Layout;