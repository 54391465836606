import React, { useContext } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import SessionProvider, { useSessionContext } from './context/sessionContext';
import ProjectProvider from './context/projectContext';
import Home from "./components/Home/Home";
import BuildingFloor from "./components/BuildingFloor/BuildingFloor";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import LockScreen from './pages/LockScreen/LockScreen';
import Privacy from './components/Privacy/Privacy';
import NonDiscrimination from './components/NonDiscrimination/NonDiscrimination';
import ProtectedRoute, { ProtectedRouteProps } from './components/ProtectedRoute/ProtectedRoute';

import HomePanel from './pages/AdminPanel/HomePanel/HomePanel';
import UsersPanel from './pages/AdminPanel/UsersPanel/UsersPanel';
import UsersForm from './components/AdminPanel/UsersForm/UsersForm';


function App() {
  const [sessionContext, updateSessionContext] = useSessionContext();

  const setRedirectPath = (path: string) => {
    updateSessionContext({...sessionContext, redirectPath: path});
  }

  if(!sessionContext.redirectPath) {
    setRedirectPath('home');
  }

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: !!sessionContext.isAuthenticated,
    authenticationPath: '/',
    redirectPath: sessionContext.redirectPath,
    setRedirectPath: setRedirectPath
  };

  return (
    <ProjectProvider>
        <Router>
          <SessionProvider>
              
                <Routes>
                  <Route path="aviso-de-privacidad" element={<Privacy />} />
                  <Route path="aviso-de-no-discriminacion" element={<NonDiscrimination />} />
                  <Route path="/home" element={<Home />} />
                  <Route path="/niveles" element={<BuildingFloor />} />
                  <Route path="/" element={<Home />} />

                </Routes>

          </SessionProvider>
        </Router>
      </ProjectProvider>
  );
}

export default App;
